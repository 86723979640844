<template>
  <section class="section competitive-edge">
    <h2>{{ $t("Explore our Competitive Edge in the Wellbeing Landscape") }}</h2>
    <p class="section__subtitle">
      {{
        $t(
          "Discover the Exclusive Edge of SportBenefit: Your Sole Corporate Wellbeing Solution with Access to 155+ Gyms, 45+ Activities, and Cyprus' Premier Wellbeing App"
        )
      }}
    </p>
    <div class="competitive-edge-table">
      <div class="competitive-edge-table-head">
        <table>
          <thead>
            <tr>
              <th></th>
              <th>
                <img
                  class="competitive-edge__brand-image"
                  :src="`image/logo/logo-sportbenefit.webp`"
                  alt="brand"
                />
              </th>
              <th class="text-h5">{{ $t(tableData.header.name.onSiteGym) }}</th>
              <th class="text-h5">
                {{ $t(tableData.header.name.reimbursement) }}
              </th>
              <th class="text-h5">{{ $t(tableData.header.name.localGym) }}</th>
            </tr>
          </thead>
        </table>
      </div>
      <div class="competitive-edge-table-body">
        <table>
          <tbody>
            <tr
              v-for="(tr, property) in tableData.body"
              :key="`competitive-edge-table-body-${property}`"
            >
              <td>
                <div>
                  <BaseIcon :icon="tr.icon" />
                  <span class="text-h5">{{ $t(property) }}</span>
                </div>
              </td>
              <td>
                <span v-if="property === 'coverage'">
                  <BaseIcon
                    v-for="(_, i) in tr.brand"
                    :key="`coverage-desktop-table-${i}`"
                    :icon="tr.icon"
                  />
                </span>
                <span v-else>{{ tr.brand }}</span>
              </td>
              <td>
                <span v-if="property === 'coverage'">
                  <BaseIcon
                    v-for="(_, i) in tr.onSiteGym"
                    :key="`coverage-desktop-table-${i}`"
                    :icon="tr.icon"
                  />
                </span>
                <span v-else>{{ tr.onSiteGym }}</span>
              </td>
              <td>
                <span v-if="property === 'coverage'">
                  <BaseIcon
                    v-for="(_, i) in tr.reimbursement"
                    :key="`coverage-desktop-table-${i}`"
                    :icon="tr.icon"
                  />
                </span>
                <span v-else>{{ tr.reimbursement }}</span>
              </td>
              <td>
                <span v-if="property === 'coverage'">
                  <BaseIcon
                    v-for="(_, i) in tr.localGym"
                    :key="`coverage-desktop-table-${i}`"
                    :icon="tr.icon"
                  />
                </span>
                <span v-else>{{ tr.localGym }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="competitive-edge-carousel">
      <Carousel wrap-around :items-to-show="1.2">
        <Slide
          v-for="(slide, key) in tableData.body"
          :key="`competitive-edge-carousel-${key}`"
        >
          <div class="competitive-edge-carousel__item">
            <div class="competitive-edge-carousel__item-title">
              <BaseIcon :icon="slide.icon" />
              <span class="text-h5"> {{ $t(key) }}</span>
            </div>
            <div class="competitive-edge-carousel__item-content">
              <table>
                <tbody>
                  <template v-for="(item, name) in slide" :key="name">
                    <tr v-if="name !== 'icon'">
                      <td>
                        <div v-if="name === 'brand'" class="text-h5">
                          <img
                            class="competitive-edge__brand-image"
                            :src="`image/logo/logo-sportbenefit.webp`"
                            alt="brand"
                          />
                        </div>
                        <p v-else class="text-h5">
                          {{ tableData.header.name[name] }}
                        </p>
                      </td>
                      <td v-if="key === 'coverage'">
                        <span
                          v-for="(_, i) in item"
                          :key="`coverage-icon-${i}`"
                        >
                          <BaseIcon icon="location" />
                        </span>
                      </td>
                      <td v-else>{{ item }}</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </Slide>

        <template #addons>
          <Pagination />
        </template>
      </Carousel>
    </div>
  </section>
</template>
<script lang="ts">
import BaseIcon from "~/components/icons/BaseIcon.vue";

export default defineNuxtComponent({
  components: { BaseIcon },
  setup() {
    const tableData = {
      header: {
        name: {
          brand: "allsports",
          onSiteGym: "On-site Gym",
          reimbursement: "Reimbursement",
          localGym: "Local gym",
        },
      },
      body: {
        price: {
          brand: "€",
          onSiteGym: "€",
          reimbursement: "€-€€€",
          localGym: "€-€€€",
          icon: "euro",
        },
        access: {
          brand: "Unlimited",
          onSiteGym: "Limited hours",
          reimbursement: "Unlimited",
          localGym: "Possible restrictions",
          icon: "access",
        },
        coverage: {
          brand: 4,
          onSiteGym: 1,
          reimbursement: 1,
          localGym: 1,
          icon: "location",
        },
        activities: {
          brand: "45+ various activities",
          onSiteGym: "Gym only",
          reimbursement: "Full service gyms and studios",
          localGym: "Gym only",
          icon: "person",
        },
        digitalSolution: {
          brand: "Yes",
          onSiteGym: "No",
          reimbursement: "No",
          localGym: "No",
          icon: "cellPhone",
        },
        referGym: {
          brand: "Yes",
          onSiteGym: "No",
          reimbursement: "No",
          localGym: "No",
          icon: "dumbbell",
        },
        extendToFamilyMember: {
          brand: "Yes",
          onSiteGym: "No",
          reimbursement: "No",
          localGym: "No",
          icon: "persons",
        },
        referralProgram: {
          brand: "Yes",
          onSiteGym: "No",
          reimbursement: "No",
          localGym: "No",
          icon: "handshake",
        },
      },
    };

    return { tableData };
  },
});
</script>
